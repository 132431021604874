<template>
  <main class="container mx-auto flex flex-col items-center mt-3 px-4">
    <span class="text-2xl text-center font-bold mb-1">{{ title }}</span>
    <span class="text-base text-center text-gray-700 mb-10">
      カメラが映らない場合は<br />下にスクロールしてください。
      <div class="text-3xl">▽</div>
    </span>
    <div v-show="!load" class="fullscreen">
      <qrcode-stream @decode="onDecode" @init="onInit" />
    </div>
  </main>
</template>

<script lang="ts">
import { signInWithCode, signInWithIdAndPassword } from "@/api/auth";
import { saveErrorLog } from "@/api/error";
import store from "@/store";
import liff from "@line/liff/dist/lib";
import { Options, Vue } from "vue-class-component";
import { QrcodeStream } from "vue3-qrcode-reader";

function isValidLoginId(loginId: string): boolean {
  return /^[0-9a-zA-Z]{6}-[0-9a-zA-Z]{3,}$/.test(loginId);
}

function isValidPassword(password: string): boolean {
  return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(password);
}

@Options({
  components: {
    QrcodeStream
  }
})
export default class AuthReader extends Vue {
  title = "スキャンするコードを見つけてください";

  get load(): boolean {
    return store.state.loading;
  }

  async onInit(promise: Promise<MediaTrackCapabilities>) {
    try {
      await promise;
    } catch (e) {
      if (e.name === "NotAllowedError") {
        this.title = "カメラへのアクセスが許可されていません";
      } else if (e.name === "NotFoundError") {
        this.title = "利用できるカメラが検出されませんでした";
      } else if (e.name === "NotReadableError") {
        this.title =
          "既にカメラが使用されているためカメラを使用することができません";
      } else {
        this.title =
          "このブラウザまたは端末ではQRコードリーダーは対応していません";
      }
    }
  }

  async onDecode(content: string) {
    if (content === "") {
      return;
    }
    const codes = content.split(" ");
    const isCode = content.length === 20 && codes.length === 1;
    if (!isCode && codes.length !== 2) {
      alert("QRコードが正しくありません");
      return;
    }

    let loginId = "";
    let password = "";
    if (!isCode) {
      loginId = codes[0];
      password = codes[1];
      if (!isValidLoginId(loginId) || !isValidPassword(password)) {
        alert("QRコードが正しくありません");
        return;
      }
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ログイン処理中...");

    let token: string | undefined;
    if (store.state.isInLiff) {
      if (!liff.isLoggedIn()) {
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        alert("LINEのログインが完了していません。アプリを開き直してください。");
        return;
      }
      const accessToken = liff.getAccessToken();
      token = accessToken ? accessToken : undefined;
    }

    try {
      if (isCode) {
        await signInWithCode(content, token);
      } else {
        await signInWithIdAndPassword(loginId, password, token);
      }
    } catch (e) {
      if (e.code === "not-found" && e.code === "permission-denied") {
        alert(
          "このQRコードは現在使用できません。\n最新のログイン用QRコードを再度スクールの先生から共有してもらってください。"
        );
      } else {
        alert(`ログインに失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to sign in"
        );
      }
      return;
    }
    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
    const path = store.state.redirect;
    store.commit("SET_REDIRECT", "/");
    this.$router.replace(path);
  }
}
</script>

<style lang="scss" scoped>
.fullscreen {
  position: fixed;
  top: 320px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.qrcode-stream-camera {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}
.qrcode-stream-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
}
</style>
